<template>
  <!-- 机票选城市 查看，不可买票 -->
  <BuyAircraftUI @searchTrain="searchTrain" @chooseCity="chooseCity"/>
</template>

<script>
import BuyAircraftUI from "./components/BuyAircraftUI";
export default {
  name: "BuyAircraftView",
  components: { BuyAircraftUI },
  methods: {
    // 搜索按钮
    searchTrain(type) {
      this.$router.push("/searchAircraftView")
    },

    // 选择城市
    chooseCity(vuxFunction) {
      this.$router.push({
        path: "/selectCity",
        query: { function: vuxFunction, allCity: true }
      });
    }
  },
};
</script>
